import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo
} from 'react'
import { JSONEditor } from 'vanilla-jsoneditor'
import { Button } from '../../../components/Button/Button'
import { CardList } from '../../../components/CardList/CardList'
import { Toggle } from '../../../components/Forms/Fields/Toggle/Toggle'
import Select from '../../../components/Forms/Inputs/Select/Select'
import { CanvasStyle, Strack } from '../../../components/Strack/Strack.types'
import { Table } from '../../../components/Table/Table'
import { deviceTypes } from '../../../const'
import { RawPacket } from '../../../metrics_server/hardware/types'
import { MqttContext } from '../../../metrics_server/mqtt/Provider'
import { getSport } from '../../../metrics_server/sports/functions'
import { useSession } from '../../../metrics_server/sessions/hooks'
import {
  getSelectedSportscaster,
  useSelectedSportscaster
} from '../../../metrics_server/sportscaster'
import styles from './Sportscaster.module.scss'
import { toggleModal } from '../../../ui/modal/actions'
import { useAppDispatch } from '../../../store/hooks'
import { useEndpointTestModal } from '../modals/endpoint_test'
import {
  useBroadcastIntegration,
  useBroadcastIntegrationSessionState
} from '../../../metrics_server/broadcast_integration/hooks'
import { TableRow } from '../../../components/Table/Table.types'
import { useUnits, useUnitsSystem } from '../../../metrics_server/units/hooks'
import { UnitSystem } from '../../../metrics_server/units/types'
import { convertYardsToMeters } from '../../../utils/strack/strack'
import { getPlayerSessionByHardwareSerial } from '../../../metrics_server/sessions/functions'
import { getPlayerFromGeniusPlayerId } from '../../../metrics_server/broadcast_integration/functions'
import { FormattedBroadcastIntegrationTeams } from '../../../metrics_server/broadcast_integration/types'
import { useVersion } from '../../../metrics_server/version/hooks'
import { useEvents } from '../../../metrics_server/events/hooks'
import { useTeams } from '../../../metrics_server/teams/hooks'
import { usePlayers } from '../../../metrics_server/players/hooks'
import { useSportscaster } from '../../../metrics_server/sportscaster/hooks'
import * as Sentry from '@sentry/react'

export const EventJson = ({ json }) => {
  const refContainer = useRef(null)
  const refEditor = useRef(null)
  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer?.current,
      props: {
        content: {
          json: json ?? {}
        },
        readOnly: true
      }
    })

    return () => {
      if (refEditor.current) {
        refEditor.current?.destroy()
        refEditor.current = null
      }
    }
  }, [])
  return (
    <div>
      <div className={styles.editorContainer} ref={refContainer}></div>
    </div>
  )
}

export const SportscasterWrapper = (props) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 9999,
            padding: '8px 16px',
            cursor: 'pointer'
          }}
        >
          Something went wrong with the Sportscaster component.
        </div>
      }
    >
      <Sportscaster {...props} />
    </Sentry.ErrorBoundary>
  )
}

export interface SportscasterProps {
  strack: Strack
  strackReady: boolean
  canvasStyle: CanvasStyle
  canvasView: string

  active: boolean

  live: boolean

  getSportscasterEvents: any
  addSportscasterEvent: any
  setSelectedSportscaster: any
  updateFlight: any
}

export const Sportscaster = ({
  strack,
  strackReady,
  live,
  getSportscasterEvents,
  addSportscasterEvent,
  setSelectedSportscaster
}: SportscasterProps) => {
  const events = useEvents()
  const teams = useTeams()
  const players = usePlayers()
  const units = useUnits()
  const sportscaster = useSportscaster()

  // Redux //
  const dispatch = useAppDispatch()
  // ===== //

  // Session //
  const sessionConfig = useSession()
  const { session, pitch } = sessionConfig
  // ====== //

  // Broadcast Integration //
  const broadcastIntegration = useBroadcastIntegration()
  const broadcastIntegrationSessionData = useBroadcastIntegrationSessionState(
    session.id
  )
  const { app } = useVersion()
  // ======= //

  const sport = getSport(session.sportType)

  const selectedSportscaster = useSelectedSportscaster()

  const clients = useContext(MqttContext)

  const [highlightedEvent, setHighlightedEvent] = useState(null)

  const [tableHeaders, setTableHeaders] = useState([])
  const [eventTableHeaders, setEventTableHeaders] = useState([])

  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0)
  const [selectedHalf, setSelectedHalf] = useState('first')

  const [kicks, setKicks] = useState([])

  const [tableProps, setTableProps] = useState({
    session: { ...session },
    players: { ...players },
    teams: { ...teams },
    selectedTeamIndex,
    selectedHalf,
    sportscaster
  })
  const [sportscasterRoute, setSportscasterRoute] = useState(null)
  const [sportscasterConfigFetched, setSportcasterConfigFetched] =
    useState(false)

  // If genius integration is enabled, use cloud broker
  useEffect(() => {
    if (broadcastIntegrationSessionData.isEnabled) {
      let cloudClientHost = null
      for (const host in clients) {
        if (clients[host].name !== 'Local') cloudClientHost = host
      }
      if (cloudClientHost) {
        setSelectedSportscaster(cloudClientHost)
      }
    }
  }, [broadcastIntegrationSessionData.isEnabled])

  const generateRowData = useCallback(
    (kick) => {
      const row = { ...kick }

      row.startTime = new Date(row.startTime).getTime() / 1000
      row.startTime = (row.startTime - session.startTime) * 1000

      return row
    },
    [session]
  )

  const getTopic = (locationName, route, unitSystem: UnitSystem) => {
    if (locationName === 'metrics_server') {
      return `${locationName}/v1/${route}/${unitSystem.key}`
    } else {
      return `${locationName}/v2/${route}/${unitSystem.key}`
    }
  }

  const unsubscribeFromSportscasterEventTopics = (clients, units) => {
    Object.values(clients).forEach((client: any) => {
      units.systemOptions.forEach((systemOption) => {
        client.unsubscribe(
          getTopic(client.locationName, 'kick', systemOption.value)
        )
        client.unsubscribe(
          getTopic(client.locationName, 'pass', systemOption.value)
        )
        client.unsubscribe(
          getTopic(client.locationName, 'lineout', systemOption.value)
        )
      })
    })
  }

  const unsubscribeFromSportscasterPositionTopics = (clients, units) => {
    Object.values(clients).forEach((client: any) => {
      units.systemOptions.forEach((systemOption) => {
        client.unsubscribe(
          getTopic(client.locationName, 'ballPos', systemOption.value)
        )
      })
    })
  }

  const generateGeniusTopics = (fixtureId) => {
    return {
      events: fixtureId ? `genius/${fixtureId}/events` : 'genius/events',
      updates: fixtureId
        ? `genius/${fixtureId}/eventsUpdates`
        : 'genius/eventsUpdates',
      ballData: fixtureId ? `genius/${fixtureId}/ballData` : 'genius/ballData',
      playerData: fixtureId
        ? `genius/${fixtureId}/playerData`
        : 'genius/playerData'
    }
  }

  const unsubscribeFromAllGeniusTelemetryTopics = (clients, fixtureId) => {
    console.log('Starting telemetry unsubscribe with fixtureId:', fixtureId)

    try {
      const topics = generateGeniusTopics(fixtureId)
      console.log('Generated telemetry topics:', topics)

      Object.values(clients).forEach((client: any) => {
        console.log(
          'Unsubscribing from genius telemetry topics: ',
          topics.ballData,
          topics.playerData
        )
        client.unsubscribe(topics.ballData)
        client.unsubscribe(topics.playerData)
      })
    } catch (error) {
      console.error('Error in telemetry unsubscribe process:', error)
    }
  }

  const unsubscribeFromAllGeniusEventTopics = (clients, fixtureId) => {
    console.log('Starting unsubscribe with fixtureId:', fixtureId)

    try {
      const topics = generateGeniusTopics(fixtureId)
      console.log('Generated topics:', topics)

      Object.values(clients).forEach((client: any) => {
        console.log(
          'Unsubscribing from genius event topics: ',
          topics.events,
          topics.updates
        )
        client.unsubscribe(topics.events)
        client.unsubscribe(topics.updates)
      })
    } catch (error) {
      console.error('Error in unsubscribe process:', error)
    }
  }

  const formatGeniusPositionData = (
    data,
    geniusOrigin,
    type,
    broadcastIntegrationTeams: FormattedBroadcastIntegrationTeams
  ) => {
    const time = new Date().getTime()
    return data.chunks[0].telemetries.map((telemetry) => {
      const packet = {} as RawPacket
      packet.type = type

      if (type === deviceTypes.ball.value) {
        const playerSession = getPlayerSessionByHardwareSerial(
          session.playersSessions,
          telemetry.ballId
        )
        packet.id = playerSession?.tag?.id
        packet.serial = telemetry.ballId
      } else if (type === deviceTypes.tag.value) {
        const broadcastIntegrationPlayer = getPlayerFromGeniusPlayerId(
          broadcastIntegrationTeams,
          telemetry.playerId
        )

        packet.id =
          broadcastIntegrationPlayer?.playerSession?.hardware?.id ||
          telemetry.playerId
        packet.serial =
          broadcastIntegrationPlayer?.playerSession?.hardware?.serial ||
          telemetry.playerId
      }

      // Perform transformation
      packet.position = {
        x: geniusOrigin.x + convertYardsToMeters(telemetry.x),
        y: geniusOrigin.y + convertYardsToMeters(telemetry.y),
        z: 0
      }

      packet.time = time / 1000
      return packet
    })
  }

  const listenToIntaneousBallData = (
    selectedSportscaster,
    clients,
    strackReady
  ) => {
    //Connect to MQTT Broker
    const client = clients[selectedSportscaster.WSSHost]

    if (!client) {
      console.log('Client not found when listening to sportscaster topics')
      return
    }

    //Subscribe to Topics
    client.subscribe(
      `broadcast/${selectedSportscaster.locationName}/ballData`,
      (data) => {
        const packet = {} as RawPacket
        packet.type = deviceTypes.ball.value
        packet.position = {
          x: data.x,
          y: data.y,
          z: data.z
        }
        packet.time = data.t
        packet.isBallInPlay = true

        if (strackReady && strack.updateBuffer) {
          strack.updateBuffer(packet)
        }
      }
    )
  }

  const listenToGeniusTelemetryTopics = (
    selectedSportscaster,
    clients,
    fixtureId,
    previousFixtureId,
    broadcastIntegrationTeams,
    strackReady
  ) => {
    unsubscribeFromAllGeniusTelemetryTopics(clients, previousFixtureId.current)

    //Connect to MQTT Broker
    const client = clients[selectedSportscaster.WSSHost]

    if (!client) {
      console.log('Client not found when listening to genius telemetry topics')
      return
    }

    //Subscribe to Topics
    const { ballData, playerData } = generateGeniusTopics(fixtureId)

    client.subscribe(ballData, (data) => {
      const packets = formatGeniusPositionData(
        data,
        pitch.coordinates.P1,
        deviceTypes.ball.value,
        broadcastIntegrationTeams
      )

      if (strackReady && strack.updateBuffer) {
        packets.forEach((packet) => {
          strack.updateBuffer(packet)
        })
      }
    })

    client.subscribe(playerData, (data) => {
      const packets = formatGeniusPositionData(
        data,
        pitch.coordinates.P1,
        deviceTypes.tag.value,
        broadcastIntegrationTeams
      )

      if (strackReady && strack.updateBuffer) {
        packets.forEach((packet) => {
          strack.updateBuffer(packet)
        })
      }
    })
  }

  const listenToGeniusEventTopics = (
    selectedSportscaster,
    clients,
    fixtureId,
    previousFixtureId
  ) => {
    unsubscribeFromAllGeniusEventTopics(clients, previousFixtureId.current)

    //Connect to MQTT Broker
    const client = clients[selectedSportscaster.WSSHost]

    if (!client) {
      console.log('Client not found when listening to genius topics')
      return
    }

    //Subscribe to Topics
    const { events, updates } = generateGeniusTopics(fixtureId)

    client.subscribe(events, (data) => {
      addSportscasterEvent(data)
    })
    client.subscribe(updates, (data) => {
      addSportscasterEvent(data)
    })
  }

  const listenToSelectedSportscasterEventTopics = (
    selectedSportscaster,
    clients,
    unitSystem
  ) => {
    unsubscribeFromSportscasterEventTopics(clients, units)

    //Connect to MQTT Broker
    const client = clients[selectedSportscaster.WSSHost]

    if (!client) {
      console.log('Client not found when listening to sportscaster topics')
      return
    }

    //Subscribe to Topics
    const kickTopic = getTopic(
      selectedSportscaster.locationName,
      'kick',
      unitSystem
    )
    const passTopic = getTopic(
      selectedSportscaster.locationName,
      'pass',
      unitSystem
    )
    const lineoutTopic = getTopic(
      selectedSportscaster.locationName,
      'lineout',
      unitSystem
    )

    client.subscribe(kickTopic, (data) => {
      addSportscasterEvent(data)
    })
    client.subscribe(passTopic, (data) => {
      addSportscasterEvent(data)
    })
    client.subscribe(lineoutTopic, (data) => {
      addSportscasterEvent(data)
    })
  }

  const listenToSelectedSportscasterPositionTopics = (
    selectedSportscaster,
    clients,
    unitSystem
  ) => {
    unsubscribeFromSportscasterPositionTopics(clients, units)

    //Connect to MQTT Broker
    const client = clients[selectedSportscaster.WSSHost]

    if (!client) {
      console.log('Client not found when listening to sportscaster topics')
      return
    }

    const ballPosTopic = getTopic(
      selectedSportscaster.locationName,
      'ballPos',
      unitSystem
    )
    console.log('Subscribing to ballPosTopic: ', ballPosTopic)
    client.subscribe(ballPosTopic, (data) => {
      console.log(data)
      const packet = {} as RawPacket
      packet.type = deviceTypes.ball.value
      packet.id = 999
      packet.serial = 'bp'
      packet.position = {
        x: data.x,
        y: data.y,
        z: data.z
      }
      packet.time = data.t

      if (strack.updateBuffer) {
        strack.updateBuffer(packet)
      }
    })
  }

  useEffect(() => {
    return () => {
      unsubscribeFromAllGeniusEventTopics(
        clients,
        broadcastIntegration.data?.currentFixture?.geniusID
      )
      unsubscribeFromAllGeniusTelemetryTopics(
        clients,
        broadcastIntegration.data?.currentFixture?.geniusID
      )

      unsubscribeFromSportscasterEventTopics(clients, units)
      unsubscribeFromSportscasterPositionTopics(clients, units)
    }
  }, [])

  const unitSystem = useUnitsSystem(sport)

  useEffect(() => {
    setTableHeaders(getTableHeaders(events))
    setEventTableHeaders(getEventTableHeaders(events))
  }, [unitSystem])

  useEffect(() => {
    setSportcasterConfigFetched(true)
  }, [sportscaster.config])

  const previousGeniusFixtureId = useRef(null)

  const previousSelectedEventsClient = useRef(null)

  useEffect(() => {
    if (sportscasterConfigFetched && live) {
      const newSelectedSportscaster = getSelectedSportscaster(sportscaster)

      if (
        previousSelectedEventsClient.current?.WSSHost ===
        newSelectedSportscaster.WSSHost
      ) {
        return
      }

      previousSelectedEventsClient.current = newSelectedSportscaster

      if (Object.keys(clients).length === 0) return
      // Check if genius integration is avaliable and enabled
      if (broadcastIntegrationSessionData.isEnabled) {
        listenToGeniusEventTopics(
          newSelectedSportscaster,
          clients,
          broadcastIntegration.data.currentFixture?.geniusID,
          previousGeniusFixtureId
        )
        previousGeniusFixtureId.current =
          broadcastIntegration.data.currentFixture?.geniusID
      } else {
        listenToSelectedSportscasterEventTopics(
          newSelectedSportscaster,
          clients,
          unitSystem
        )
      }
    }
  }, [
    sportscasterConfigFetched,
    sportscaster,
    unitSystem,
    broadcastIntegration.data.currentFixture
  ])

  const previousSelectedTelemetryClient = useRef(null)
  const previousStrackReady = useRef(strackReady)

  useEffect(() => {
    if (strackReady && live) {
      const newSelectedSportscaster = getSelectedSportscaster(sportscaster)

      if (
        previousStrackReady.current !== strackReady ||
        previousSelectedTelemetryClient.current?.WSSHost !==
          newSelectedSportscaster.WSSHost
      ) {
        previousSelectedTelemetryClient.current = newSelectedSportscaster
        previousStrackReady.current = strackReady
        if (Object.keys(clients).length === 0) return
        // Check if genius integration is avaliable and enabled
        if (broadcastIntegrationSessionData.isEnabled) {
          listenToGeniusTelemetryTopics(
            newSelectedSportscaster,
            clients,
            broadcastIntegration.data.currentFixture?.geniusID,
            previousGeniusFixtureId,
            broadcastIntegrationSessionData.teamsAndPlayers,
            strackReady
          )
          previousGeniusFixtureId.current =
            broadcastIntegration.data.currentFixture?.geniusID
        } else if (app?.AFLWIntegration?.Enabled) {
          // Listen to intaneous ball data topics
          listenToIntaneousBallData(
            newSelectedSportscaster,
            clients,
            strackReady
          )
        } else {
          listenToSelectedSportscasterPositionTopics(
            newSelectedSportscaster,
            clients,
            unitSystem
          )
        }
      }
    }
  }, [
    sportscaster,
    broadcastIntegrationSessionData.teamsAndPlayers,
    strackReady
  ])

  useEffect(() => {
    if (
      sportscaster.data.payload &&
      sportscaster.data.payload.teams[selectedTeamIndex]
    ) {
      setTableProps({
        ...tableProps,
        sportscaster,
        selectedTeamIndex,
        selectedHalf
      })
      setKicks(
        sportscaster.data.payload.teams[selectedTeamIndex].halves[selectedHalf]
          .kicks
      )
    }
  }, [selectedTeamIndex, selectedHalf, sportscaster])

  const { openEndpointTestModal } = useEndpointTestModal()

  const fetchFlightData = (selectedSportscaster, sportscasterRoute) => {
    getSportscasterEvents(selectedSportscaster, sportscasterRoute)
  }

  const toggleViewJsonModal = (payload) => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: (values) => {
          dispatch(toggleModal({}))
        },
        wrapper: true,
        ChildComponent: () => <EventJson json={payload} />,
        className: 'modalSmall',
        title: 'Event details'
      })
    )
  }

  const getTableHeaders = (events) => {
    return [
      { name: 'Time', key: 'startTime', width: 25, type: 'time' },
      { name: 'Team', key: 'teamLogo', width: 25 },
      { name: 'Player', key: 'fromPlayer', width: 25 },
      { name: 'Type', key: 'type', width: 25 }
      // {name: '', key: 'analyse', width: 9, callback: (item, bool) => updateAnalysisList({...item, 'analyse': !bool})}
    ]
  }

  const getEventTableHeaders = (events) => {
    return [
      { name: 'Created', key: 'eventTimestamp', width: 40, type: 'date' },
      { name: 'Type', key: 'eventType', width: 40 },
      {
        name: 'view payload',
        key: 'button',
        width: 20,
        input: {
          type: 'button',
          callback: (rowData) => {
            toggleViewJsonModal(rowData.payload)
          }
        }
      }

      // {name: '', key: 'analyse', width: 9, callback: (item, bool) => updateAnalysisList({...item, 'analyse': !bool})}
    ]
  }

  const eventsTableData = useMemo(() => {
    return sportscaster.events.map((event: any) => {
      if (broadcastIntegrationSessionData.isEnabled) {
        const row = {
          id: event.id
        } as TableRow
        row.payload = event
        row.eventType = event.type || event.eventType
        row.eventTimestamp = new Date(
          event.utcTimestamp || event.createdAt
        ).getTime()
        return row
      } else {
        const row = { ...event } as TableRow
        row.payload = event
        row.eventType = event.type
        row.eventTimestamp = new Date(row.createdAt).getTime()
        return row
      }
    })
  }, [sportscaster.events])

  let teamOptions = []

  const trajectoryRouteOptions = sportscaster.routeOptions.filter(
    (x) => x.trajectory
  )

  if (
    sportscaster.data.payload &&
    sportscaster.data.payload.teams[0] &&
    sportscaster.data.payload.teams[1]
  ) {
    teamOptions = [
      { value: 0, name: sportscaster.data.payload.teams[0].name },
      { value: 1, name: sportscaster.data.payload.teams[1].name }
    ]
  }

  const halfOptions = [
    { value: 'first', name: 'First half' },
    { value: 'second', name: 'Second half' }
  ]

  const sportscasterOptions = []
  for (const host in clients) {
    sportscasterOptions.push({ name: host, value: host })
  }

  return (
    <>
      {/* TODO: Add Strack Events - Need to format them first */}
      <div className={styles.filterContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.toggles}>
            <div>
              <div className={styles.selectContainer}>
                <Select
                  className='small-select'
                  options={sportscasterOptions}
                  updateValue={(options, value) => {
                    setSelectedSportscaster(value)
                  }}
                  input={{
                    value: selectedSportscaster.WSSHost,
                    name: selectedSportscaster.name
                  }}
                />
              </div>
              <div className={styles.healthContainer}>
                <div className={styles.configLabel}>v:</div>
                {sportscaster.health[selectedSportscaster.WSSHost]?.version
                  ? sportscaster.health[selectedSportscaster.WSSHost].version
                  : '-'}
              </div>
              <div className={styles.healthContainer}>
                <div className={styles.configLabel}>healthy:</div>
                {sportscaster.health[selectedSportscaster.WSSHost]?.mqtt &&
                sportscaster.health[selectedSportscaster.WSSHost].healthy ? (
                  <div className={styles.greenLight}></div>
                ) : (
                  <div className={styles.redLight}></div>
                )}
              </div>
              <div className={styles.configStat}>
                <div className={styles.configLabel}>HTTP:</div>
                {selectedSportscaster.SportscasterHost
                  ? selectedSportscaster.SportscasterHost
                  : '-'}
              </div>
              <div className={styles.configStat}>
                <div className={styles.configLabel}>Broker:</div>
                {selectedSportscaster.WSSHost
                  ? selectedSportscaster.WSSHost
                  : '-'}
              </div>
              <div className={styles.testButton}>
                <Button
                  className={'link'}
                  handleClick={() => openEndpointTestModal()}
                >
                  Test Endpoints
                </Button>
              </div>
            </div>
            <div>
              {broadcastIntegrationSessionData.isEnabled && (
                <>
                  <div className={styles.configStat}>
                    <div className={styles.configLabel}>Genius Fixture ID:</div>
                    {broadcastIntegration.data.currentFixture?.geniusID}
                  </div>
                  {broadcastIntegration.data.currentFixture?.geniusID && (
                    <div className={styles.configStat}>
                      <div className={styles.configLabel}>Genius Team IDs:</div>
                      {Object.values(broadcastIntegration.data.teamIds).map(
                        (team) => {
                          return (
                            <div key={team.geniusID}>
                              {team.matchedAgainst}: {team.geniusID}
                            </div>
                          )
                        }
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </CardList>
      </div>
      <div className={styles.kicksFromHand}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          {broadcastIntegrationSessionData.isEnabled ? (
            <div className={styles.eventLogContainer}>
              {!sportscaster.health[selectedSportscaster.WSSHost]?.mqtt ? (
                <h5>Sportscaster MQTT connection failed</h5>
              ) : (
                <React.Fragment>
                  <div className={styles.eventLogHeader}>
                    <h5>Event log</h5>
                  </div>
                  <div className={styles.eventLogTable}>
                    <Table
                      // Row props
                      id='sportscaster-event-table'
                      // highlightRow={setHighlightedEvent}
                      highlightedRow={'-'}
                      options={{
                        initialOrder: live ? 'dec' : 'asc',
                        initialSortBy: 'eventTimestamp',
                        sortActive: true
                      }}
                      headerFont={13}
                      tableClass={'minimalistBlack'}
                      className={'container'}
                      data={eventsTableData}
                      headers={eventTableHeaders}
                      smallHead={true}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : (
            <div className={styles.kicksFromHandContainer}>
              {' '}
              <div className={styles.filterRow}>
                <div className={styles.filterColumn}>
                  <div className={styles.filterSelectContainer}>
                    <div className={styles.filterLabel}>Endpoint:</div>
                    <div
                      className={`smallSelectContainer ${styles.filterSelect}`}
                    >
                      <Select
                        className='small-select'
                        options={trajectoryRouteOptions}
                        input={{ name: 'route', value: sportscasterRoute }}
                        updateValue={(name, value) =>
                          setSportscasterRoute(value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.filterColumn}>
                  <div className={styles.testButton}>
                    <Button
                      className={'link'}
                      handleClick={() =>
                        fetchFlightData(selectedSportscaster, sportscasterRoute)
                      }
                    >
                      Fetch
                    </Button>
                  </div>
                </div>
              </div>
              {sportscaster.health[selectedSportscaster.WSSHost]?.http
                ?.error ? (
                <h5>
                  {
                    sportscaster.health[selectedSportscaster.WSSHost]?.http
                      ?.error
                  }
                </h5>
              ) : (
                <>
                  <></>
                  {sportscaster.fetched ? (
                    <>
                      <div className={styles.kicksFromHandFilters}>
                        <Toggle
                          input={{
                            value: selectedTeamIndex,
                            onChange: setSelectedTeamIndex
                          }}
                          options={teamOptions}
                        />
                        <Toggle
                          input={{
                            value: selectedHalf,
                            onChange: setSelectedHalf
                          }}
                          options={halfOptions}
                        />
                      </div>
                      <div className={styles.kicksFromHandTable}>
                        <Table
                          // Row props
                          highlightRow={setHighlightedEvent}
                          highlightedRow={highlightedEvent}
                          id='sportscaster-kick-table'
                          options={{
                            initialOrder: live ? 'dec' : 'asc',
                            initialSortBy: 'startTime',
                            sortActive: true
                          }}
                          headerFont={13}
                          tableClass={'minimalistBlack'}
                          className={'container'}
                          data={kicks}
                          generateRowData={generateRowData}
                          headers={tableHeaders}
                          smallHead={true}
                        />
                      </div>
                    </>
                  ) : (
                    <h5>
                      {
                        sportscaster.health[selectedSportscaster.WSSHost]?.http
                          ?.error
                      }
                    </h5>
                  )}
                </>
              )}
            </div>
          )}
        </CardList>
      </div>
      <div className={styles.eventLog}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.eventLogContainer}>
            {!broadcastIntegrationSessionData.isEnabled && (
              <>
                <></>
                {!sportscaster.health[selectedSportscaster.WSSHost]?.mqtt ? (
                  <h5>Sportscaster MQTT connection failed</h5>
                ) : (
                  <React.Fragment>
                    <div className={styles.eventLogHeader}>
                      <h5>Event log</h5>
                    </div>
                    <div className={styles.eventLogTable}>
                      <Table
                        // Row props
                        id='sportscaster-event-table'
                        // highlightRow={setHighlightedEvent}
                        highlightedRow={'-'}
                        options={{
                          initialOrder: live ? 'dec' : 'asc',
                          initialSortBy: 'eventTimestamp',
                          sortActive: true
                        }}
                        headerFont={13}
                        tableClass={'minimalistBlack'}
                        className={'container'}
                        data={eventsTableData}
                        headers={eventTableHeaders}
                        smallHead={true}
                      />
                    </div>
                  </React.Fragment>
                )}
              </>
            )}
          </div>
        </CardList>
      </div>
    </>
  )
}
